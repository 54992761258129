
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.bold {
  font-weight: 600;
}

.icon_info {
  fill: $c-main-primary;
  width: 24px;
  height: 24px;
  margin-left: 1rem;
  margin-top: -2px;
}

h1.h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 100% */
  text-transform: uppercase;
}

h2.titleForm {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: $c-main-primary;
  padding-top: 6rem;
}

h3.titleForm {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #333333;
  width: 100%;
  padding: 3rem 0 3rem 0;
  display: flex;

  span.alignMiddle {
    vertical-align: middle;
  }
}

.label_switcher {
  width: 75%;
  padding-right: 5rem;
  color: #333333;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.right {
  text-align: right;
}

div.sectionBidirectionnel {
  padding-bottom: 3rem;
}

.fileExist {
  padding-bottom: 1em;
}

.icon_trash {
  fill: $c-main-primary;
  width: 24px;
  height: 24px;
  margin-left: 1rem;
}

.profile-pic-upload-block {
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 80%;
  }
}

button {
  border: none;
  border-radius: 50px;
  background-color: $c-orange;
  color: $c-white;
  outline: 0;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px 8px 12px;
}

.action_add {
  padding-top: 5em;
  padding: 2em 2em 0 0;
  text-align: right;
}

.sectionDate {
  padding-bottom: 1rem;

  .label_switcher {
    padding-bottom: 1.5rem;
  }
}

.mb-s {
  margin-bottom: 2rem;
}

.phone {
  width: 100%;
  display: flex;
}

.header__icon {
  width: 3.8rem;
  height: 3.8rem;
  margin: 0 0.5rem 0 -1rem;

  &.elec {
    fill: $c-main-secondary;
  }

  &.gaz {
    fill: $c-orange;
  }

  &.propane {
    fill: $c-blue;
  }

  @include mq(s) {
    width: 4.8rem;
    height: 4.8rem;
    margin: 0 0.5rem 0 -1.5rem;
  }
}

#switcherPublic {
  border: none;
}

::v-deep {
  .vs__open-indicator {
    fill: $c-main-primary;
  }

  div.vdp-datepicker {
    span.selected {
      color: white;
    }
  }

  div.mt-m {
    margin-top: 20px;
  }

  div.rgpd-mention div.mb-s {
    margin-bottom: 1px;
  }

  div.rgpd-mention label {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: #333333;
  }

  div.captcha-form div.fatline::after {
    background-color: white;
  }

  .headline {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    display: flex;

    .icon_info {
      margin-top: 0px;
    }
  }

  div.headline {
    label {
      display: flex;
    }
  }

  #switcherPublic ul {
    height: 75px;
    width: 220px;
    border: none;

    li {
      border-radius: 10px;
    }

    li.list__item {
      padding-top: 19px;
      text-align: center;
      width: 50%;
      border: 1px solid $c-orange;
    }
    li:nth-child(3) {
      margin-left: 10px;
    }
  }

  .vue-file-agent {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}
