
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        























.switcher,
[class*='switcher--'] {
  @extend %border-radius;

  position: relative;
  display: inline-block;
  overflow: hidden;
  background: $c-white;
  border: 1px solid $c-gray;
  transition: border 0.3s;
}

.list {
  position: relative;
  z-index: 1;
  display: flex;
  padding: 2px;

  [class*='switcher--'][class*='--full'] & {
    padding: 0;
  }

  [class*='switcher--'][class*='--dual'] & {
    padding: 5px;
  }
}

.list__item {
  @extend %fw-medium;
  // prettier-ignore
  @include fluid(padding-right, (xxs: 10px, m: 20px));
  // prettier-ignore
  @include fluid(padding-left, (xxs: 10px, m: 20px));
  // prettier-ignore
  @include fluid(font-size, (xxs: 13px, m: 15px));

  padding-top: 1rem;
  padding-bottom: 1rem;
  color: $c-gray;
  cursor: pointer;
  transition: color 0.5s;

  &:hover {
    color: $c-black;
  }

  [class*='switcher--'][class*='--large'] & {
    // prettier-ignore
    @include fluid(padding-top, (xxs: 10px, m: 20px));
    // prettier-ignore
    @include fluid(padding-right, (xxs: 30px, m: 80px));
    // prettier-ignore
    @include fluid(padding-bottom, (xxs: 10px, m: 20px));
    // prettier-ignore
    @include fluid(padding-left, (xxs: 30px, m: 80px));
  }

  [class*='switcher--'][class*='--full'] & {
    position: relative;
    font-weight: normal;

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background: $c-gray;
      }
    }
  }

  [class*='switcher--'][class*='--dual'] & {
    position: relative;
    font-weight: normal;
  }

  &.is-active {
    color: $c-white;
    cursor: default;
  }
}

.slider {
  position: absolute;
  top: 2px;
  bottom: 2px;
  width: 0;
  background: $c-orange;
  border-radius: 10px;

  [class*='switcher--'][class*='--full'] & {
    top: 0;
    bottom: 0;
    border-radius: 0;
    transform: scaleX(1.07);
  }

  [class*='switcher--'][class*='--full'][class*='--large'] &,
  [class*='switcher--'][class*='--dual'][class*='--large'] & {
    transform: scaleX(1.03);
  }
}
